import { readable } from "svelte/store"
import { pwa } from "./pwaStore";

let images = {
    loading: true,
    list:  {},
}

let init = false;
let initPromise:Promise<{}>

let update = () => {}

function load( callback?: Function ) {

    console.info('themeimageStore:load');

    initPromise = new Promise((resolve) => {
        fetch('api/themeimage',{
            method: 'GET'
        }).then(
            response => response.json()
        ).then(data => {
            // Process the data returned from the API
            data.every((image) => {
                images.list[image.slug] = image
                images.list[image.slug].url = '/api/themeimage/'+image.id+'/'+image.file.src
                return true
            })
            if (typeof callback === 'function') {
                callback();
            }
            update();
            resolve(true);
        }).catch(error => {
            // Handle any errors that occur during the fetch
            console.error('Error:', error);
            resolve(false);
        });
    });

}

function loaded (callback?:Function) {

    if (!init) {
        init = true
        load()
    }

    callback = callback || (() => { return true })

    return new Promise((resolve) => {
        initPromise.then(() => {
            console.info('imageStore:loaded')
            resolve(callback());
        })
    })

}

function preLoad () {

    console.info('imageStore:preLoad')

    return new Promise((resolve) => {
        loaded().then(()=>{
            let imageURLs = [];
            Object.entries(images.list).every(([slug, image]) => {

                imageURLs.push(image['url']+'?pwa=1')
                return true;
        
            })
            preLoadImage(imageURLs).then(()=>{
                resolve(true)
            })
        })
    })

}

function preLoadImage ( imageURLs:Array<string> ) {

    console.info('imageStore:preLoadImage', imageURLs)

    imageURLs = imageURLs.slice(0)
    
    let promise = new Promise((resolve) => {
        let URL = imageURLs[0]
        imageURLs.splice(0, 1)
        fetch(URL,{
            method: "GET", 
            referrerPolicy: "no-referrer", 
            mode: "no-cors"}).then(()=>{
            if (imageURLs.length) {
                preLoadImage(imageURLs).then(()=>{
                    resolve(true)
                })
            } else {
                resolve(true)
            }
        })
    })

    return promise;

}

function openFileInNewTab( URL:string ) {

    console.info('imageStore:openFileInNewTab', URL)

    if (pwa.isOffline()) {
        URL = URL + '?pwa=1'
    }

    fetch(URL,{
        method: "GET", 
        referrerPolicy: "no-referrer", 
        mode: "cors"
    }).then((response)=>{
        console.warn('imageStore:openFileInNewTab',response)
    })

    

    return true

}

async function getURL( slug:string ) {

    await loaded()

    let imageURL = images.list[slug].url

    return imageURL

}

function create() {

    console.info('themeimageStore:create')

    const { subscribe } = readable(images, set => {
        update = () => set(images);
    });

	return {
        subscribe,
        loaded,
        getURL,
        preLoad,
        preLoadImage,
        openFileInNewTab
	};
}

export const themeimage = create()