<script lang="ts">

    import { categories } from '../stores/categoryStore';
    import { location } from '../stores/locationStore';
    import { i18n } from '../stores/i18nStore';
    import { language } from '../stores/languageStore';
    import LanguageSelect from './LanguageSelect.svelte';
    import Searchform from './Searchform.svelte'
    import Themeimage from './ThemeImage.svelte'
    import Link from './Link.svelte'

    let navOpen = false;

    function toogleNav () {

        navOpen = !navOpen;

    }

    function closeNav () {

        navOpen = false;

    }

</script>

<section class="header bg-blue text-white">
    <div class="container-full grid">
        <div class="col-md-4 col-xs-3 col-12  header-logo-container">
            <a href={location.getURL([], $language.selected.slug)} on:click|preventDefault={() => location.pushState(location.getURL([], $language.selected.slug))}>
                <Themeimage slug='logo-white' alt='MWS' class='header-logo' />
            </a>
        </div>
        <div class="col-md-4 col-xs-6 col-12 header-search">
            <Searchform />
        </div>
        <div class="col-md-4 col-xs-3 col-12 header-links">
            <div class="header-link-container"><a href={i18n.getString('url-contact')} class="link-uppercase link-hover" target="_blank"><i class="fal fa-user"></i>{i18n.getString('header-contact', $language.selected.slug)}</a></div>
            <div class="header-link-container"><a href={i18n.getString('url-contact')} class="link-uppercase link-hover" target="_blank"><i class="fal fa-phone-volume"></i>{i18n.getString('header-support', $language.selected.slug)}</a></div>
            <LanguageSelect />
        </div>
    </div>
</section>

<section class="header-nav bg-white" class:open={navOpen}>
    <div class="container-full">
        <div class="menu-opener" on:click={toogleNav}>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <ul on:click={closeNav}>
            {#each categories.get_MainCats() as cat}
                <li><Link path={[cat.id]} text={cat.name}/></li>
            {/each}
        </ul>
    </div>
</section>

<style>
    .header {
        font-size: 1rem;
    }
    .header .container-full.grid {
        padding: 0 1em;
    }
    .header-logo-container :global(.header-logo) {
        height: 100%;
        width: 100%;
        max-width: 350px;
        object-fit: contain;
        object-position: left center;
    }
    .header-logo-container a {
        display: block;
        min-height: 3em;
        height: 100%;
    }
    .header-search {
        display: flex;
        align-items: center;
    }

    .header-links {
        display: flex;
        justify-content: end;
        align-items: end;
        gap: 0.5em;
    }
    .header-links a,
    .header-links a:active,
    .header-links a:visited {
        text-decoration: none;
        color: inherit;
        transition: color 0.2s ease-out;
    }
    .header-links a.link-hover:hover {
        color: #007de3;
    }
    .header-links a.link-uppercase {
        text-transform: uppercase;
    }
    .header-link-container {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0 1em;
    }
    .header-link-container :global(a) {
        font-size: 0.8em;
        white-space: nowrap;
        line-height: 2em;
    }
    .header-link-container :global(a i) {
        margin-right: 0.5em;
        font-size: 1.25em;
    }
    .header-nav {
        box-shadow: 0px 2px 2px #0030575b;
    }
    .header-nav ul {
        display: flex;
        flex-flow: row nowrap;
        justify-content: end;
        padding-left: 0;
        margin: 0;
        list-style: none;
        color: #003057;
    }
    .header-nav li :global(a) {
        font-weight: 600;
        padding: 0.65em 0.75em;
        display: block;
    }
    .header-nav .menu-opener {
        display: none;
        flex-flow: column;
        gap: 5px;
        justify-content: center;
        align-items: stretch;
        width: 2em;
        height: 2em;
        margin-left: auto;
        margin-top: 5px;
        margin-bottom: 5px;
        cursor: pointer;
    }
    .header-nav .menu-opener span {
        border-top: 3px solid #003057;
        transition: all 0.2s ease-out;
        opacity: 1;
    }
    /* < md */
    @media (max-width: 991px) {
        .header-nav .container-full {
            position: relative;
        }
        .header-nav ul {
            position: absolute;
            top: 100%;
            right: 0;
            flex-flow: column;
            z-index: 999;
            background-color: #fff;
            display: none;
            box-shadow: 0px 2px 2px #0030575b;
        }
        .header-nav.open ul {
            display: flex;
        }
        .header-nav .menu-opener {
            display: flex;
        }
        .header-nav.open .menu-opener span:nth-child(1) {
            transform: translate(0px ,8px) rotate(45deg);
        }
        .header-nav.open .menu-opener span:nth-child(2) {
            transform: rotate(-45deg);
            opacity: 0;
        }
        .header-nav.open .menu-opener span:nth-child(3) {
            transform: translate(0px ,-8px) rotate(-45deg);
        }
        .header-links {
            flex-flow: column;
            align-items: end;
        }
        .header-link-container {
            padding: 0;
        }
    }
    /* < xs */
    @media (max-width: 575px) {
        .header-links {
            flex-flow: row;
            align-items: end;
            gap: 1em;
        }
    }
</style>