<script lang="ts">

    import { products } from "../stores/productStore"
    import { categories } from '../stores/categoryStore'
    import { page } from '../stores/pageStore'
    import I18nString from "./I18nString.svelte";
    import Link from "./Link.svelte"

</script>

<div class="breadcrumb">
    <ol>
        <li><I18nString slug="breadcrumb-prefix" /></li>
        {#if $page.is404}
            <li>404</li>
        {:else if $page.isSelected}
            <li><Link path={[$page.selected['slug']]} text={$page.selected['title']} /></li>
        {:else if !$categories.selected}
            <li><Link text='Premium Cutting Solutions' /></li>
        {:else}
            {@const catpath = categories.get_path($categories.selected)}
            {#each catpath as cat}
                <li><Link path={[cat]} text={categories.get_name(cat)} /></li>
            {/each}
            {#if $products.selected}
                <li><Link path={[$products.selected]} text={products.getName()} /></li>
            {/if}
        {/if}
    </ol>
</div>

<style>
    .breadcrumb {
        color: #808080;
        font-size: 0.9em;
        margin-top: 3em;
        margin-bottom: 2em;
    }
    .breadcrumb :global(p) {
        margin: 0;
    }
    .breadcrumb :global(a) {
        color: inherit;
        text-decoration: none;
    }
    .breadcrumb li:last-child :global(a) {
        color: #003057;
        font-weight: 500;
    }
    .breadcrumb ol {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        gap: 0.5em;
        flex-flow: row wrap;
    }
    .breadcrumb li:first-child {
        font-weight: 500;
        white-space: nowrap;
    }
    .breadcrumb li:not(:first-child)::before {
        display: inline;
        content: '/';
        padding-right: 0.5em;
    }
</style>