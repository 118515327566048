<script lang="ts">

    import Themeimage from './ThemeImage.svelte'
    import { location } from '../stores/locationStore'
    import { i18n } from '../stores/i18nStore'

</script>

<section class="banner">
    <div class="bg-image">
        <Themeimage slug='bannerimage' />
    </div>
    <div class="container-full grid">
        <div class="col-lg-6 col-md-7 col-sm-8 col-12 bg-blue text-white banner-box">
            <h6 class="banner-box-subline">premium cutting solutions</h6>
            <h1 class="banner-box-headline">
                {$location.title}
            </h1>
        </div>
    </div>
</section>

<style>
    .banner {
        margin-bottom: 2em;
        min-height: 65vh;
        display: flex;
    }
    .banner > .grid {
        flex-flow: column;
        justify-content: end;
        padding-top: 4em;
    }
    .banner-box {
        flex-basis: 30vh;
        margin-top: auto;
        background-color: #003057cc;
        color: #fff;
        padding: 1em;
    }
    .banner-box-subline {
        border-top: 1px solid #0962ae;
        padding-top: 1em;
        margin-top: 1em;
        margin-bottom: 1em;
        font-weight: 400;
        color: inherit;
    }
    .banner-box-headline {
        margin: 0;
    }
</style>