import { readable } from "svelte/store"
import { language } from "./languageStore"
import { categories } from "./categoryStore"
import { location } from "./locationStore"
import { pwa } from "./pwaStore"
import { themeimage } from "./themeimageStore"
import { attributes } from "./attributeStore"

let filteredProducts = {
    list: [],
    idList : [],
    selected: null,
    search: null,
}

let searchProducts:Array<string>
let categoryProducts:Array<string> = []
let attributeProducts:Array<string>
let allProducts = {}
let allPDFs = {}
let update = () => {}
let loading = [];
let loadingProducts = []
let loadingPDFs = []

function load( missingProducts:Array<string>) {

    console.info('productStore:load');

    let productsToLoad = []
    missingProducts.every((productID) => {
        if (loadingProducts.indexOf(productID) === -1) {
            productsToLoad.push(productID);
            loadingProducts.push(productID);
        }
        return true
    });

    let promise = new Promise((resolve) => {

        if (productsToLoad.length === 0) {
            resolve(true);
            return
        }

        let getParams = []
        getParams.push('lang='+language.getApiCode())
        if (pwa.isOffline()) {
            getParams.push('pwa=1')
        }

        fetch('/api/products?'+getParams.join('&')+'&in='+productsToLoad.join(','),{
            method: 'GET'
        }).then(
            response => response.json()
        ).then(data => {
            // Process the data returned from the API
            //allProducts = data;
            data.forEach((prod) => {
                allProducts[prod.id] = prod
            })
            resolve(true);
        }).catch(error => {
            // Handle any errors that occur during the fetch
            console.error('Error:', error);

            // remove Products from loading Products so they can be requested again later
            productsToLoad.forEach((productID) => {
                const index = loadingProducts.indexOf(productID);
                if (index > -1) { // only splice array when item is found
                    loadingProducts.splice(index, 1);
                }
            })

            resolve(false);
        });

    })

    loading.push(promise)

    return promise;

}

function loadPDF( missingPDFs:Array<string>) {

    console.info('productStore:loadPDF');

    let PDFsToLoad = []
    missingPDFs.every((productID) => {
        if (loadingPDFs.indexOf(productID) === -1) {
            PDFsToLoad.push(productID);
            loadingPDFs.push(productID);
        }
        return true
    });

    let promise = new Promise((resolve) => {

        if (PDFsToLoad.length === 0) {
            resolve(true);
            return
        }

        let getParams = []
        getParams.push('lang='+language.getApiCode())
        if (pwa.isOffline()) {
            getParams.push('pwa=1')
        }

        fetch('/api/product_pdf?'+getParams.join('&')+'&in='+PDFsToLoad.join(','),{
            method: 'GET'
        }).then(
            response => response.json()
        ).then(data => {
            // Process the data returned from the API
            //allProducts = data;
            data.forEach((asset) => {
                allPDFs[asset.productID] = asset.pdf
            })
            resolve(true);
        }).catch(error => {
            // Handle any errors that occur during the fetch
            console.error('Error:', error);

            // remove PDFs from loading PDFs so they can be requested again later
            PDFsToLoad.forEach((productID) => {
                const index = loadingPDFs.indexOf(productID);
                if (index > -1) { // only splice array when item is found
                    loadingPDFs.splice(index, 1);
                }
            })

            resolve(false);
        });

    })

    loading.push(promise)

    return promise;

}

function loaded () {

    return new Promise((resolve) => {

        Promise.allSettled(loading).then(() => {
            console.info('productStore:loaded')
            resolve(true)
        });

    });

}

function applyFilter ( _nested = false ) {

    console.info('productStore:applyFilter')

    return new Promise((resolve) => {

        loaded().then(() => {

            //console.trace('productStore:applyFilter:Promise', categoryProducts)

            filteredProducts.list = [];
            filteredProducts.idList = [];
            update();

            let missingProducts:Array<string> = []

            let productsToShow:Array<string> = []

            if (typeof searchProducts !== 'undefined') {
                productsToShow = searchProducts;
            } else {
                productsToShow = categoryProducts;
            }

            let productcount = 0;
            productsToShow.every((prodID) => {
                if (typeof attributeProducts !== 'undefined' && !attributeProducts.includes(prodID)) {
                    return true;
                }
                if (filteredProducts.selected !== null) { // filter for only related products on product detail page
                    console.debug('productStore:applyFilter:productSelected')
                    if (productcount >= 4) {
                        return false; // end loop
                    }
                    if (filteredProducts.selected === prodID) { // exclude the product itself
                        return true;
                    }
                }
                productcount++;
                if (typeof allProducts[prodID] === 'undefined') {
                    missingProducts.push(prodID);
                    return true;
                }
                filteredProducts.list.push(allProducts[prodID])
                filteredProducts.idList.push(prodID)
                return true;
            })

            if (missingProducts.length && !_nested) {
                loadMissingProducts(missingProducts)
                loaded().then(() => {
                    applyFilter(true).then(() => {
                        resolve(true)
                    })
                });
            } else {
                update()
                resolve(true)
            }

        })

    })

}

function loadMissingProducts ( productList:Array<string> ) {

    console.info('productStore:loadMissingProducts', productList)

    productList = productList.slice(0)

    let n = 250;

    let promise = new Promise((resolve) => {
        let productsToLoad = productList.slice(0, n)
        productList.splice(0, n)
        load(productsToLoad).then(() => {
            if (productList.length) { // more products to load
                loadMissingProducts(productList).then(()=>{
                    resolve(true)
                })
            } else { // all products loaded
                resolve(true)
            }
        })
    })

    loading.push(promise)

    return promise;

}

function loadMissingPDFs ( productList:Array<string> ) {

    console.info('productStore:loadMissingPDFs', productList)

    productList = productList.slice(0)

    let n = 100;

    let promise = new Promise((resolve) => {
        let productsToLoad = productList.slice(0, n)
        productList.splice(0, n)
        loadPDF(productsToLoad).then(() => {
            if (productList.length) { // more products to load
                loadMissingPDFs(productList).then(()=>{
                    resolve(true)
                })
            } else { // all products loaded
                resolve(true)
            }
        })
    })

    loading.push(promise)

    return promise;

}

function preLoadImage () {

    console.info('productStore:preLoadImage')

    return new Promise((resolve) => {
        loaded().then(()=>{
            let imageURLs = [];
            Object.entries(allProducts).every(([ID, product]) => {

                if (product['image']['small']) {
                    imageURLs.push(product['image']['small']+'?pwa=1')
                }
                if (product['image']['large']) {
                    imageURLs.push(product['image']['large']+'?pwa=1')
                }
                if (product['image']['full']) {
                    //imageURLs.push(product['image']['full']+'?pwa=1')
                }
                return true;
        
            })

            imageURLs = imageURLs.filter((value, index, array) => array.indexOf(value) === index);
            themeimage.preLoadImage(imageURLs).then(()=>{
                resolve(true)
            })
        })
    })

}
function preLoadPDFs () {

    console.info('productStore:preLoadPDFs')

    return new Promise((resolve) => {
        loaded().then(()=>{
            let pdfURLs = [];
            Object.entries(allPDFs).every(([productID, pdfURL]) => {

                pdfURLs.push(pdfURL+'?pwa=1')
                return true;
        
            })

            pdfURLs = pdfURLs.filter((value, index, array) => array.indexOf(value) === index);
            themeimage.preLoadImage(pdfURLs).then(()=>{
                resolve(true)
            })
        })
    })

}

function setCategoryFilter( productList:Array<string> ) {

    console.info('productStore:setCategoryFilter', productList)

    filteredProducts.list = []
    categoryProducts = productList
    
}

function setAttributeFilter(  productList:Array<string>  ) {

    console.info('productStore:setAttributeFilter', productList)

    attributeProducts = productList

}

function searchOffline ( searchString:string ) {

    let promise = new Promise((resolve) => {

        if (searchString.length < 2) {
            resolve(true)
            return
        }

        let attributeValueIDs = attributes.searchAttributeValue(searchString)

        const searchRegExp = new RegExp(searchString, "i");

        let i = 0
        Object.entries(allProducts).every(([productID, product]) => {
            console.log('searchOffline',product['name'],product['sku'],searchRegExp)
            if (product['name'].match(searchRegExp) !== null) {
                searchProducts.push(productID)
                i++;
            } else if (product['sku'].match(searchRegExp) !== null) {
                searchProducts.push(productID)
                i++;
            } else if(intersect(product['attributes'], attributeValueIDs).length) {
                searchProducts.push(productID)
                i++;
            }
            if (i < 100)  {
                return true
            }
            return false // stop at 100 products found
        })

        applyFilter();
        resolve(true)

    })

    loading.push(promise)

    return promise

}

function intersect(a, b) {
    var setB = new Set(b);
    return [...new Set(a)].filter(x => setB.has(x));
}

function search( searchString:string ) {

    console.info('productStore:search', searchString)

    filteredProducts.search = searchString;
    searchProducts = []
    update()

    if (pwa.isOffline()) {
       searchOffline(searchString)
       return
    }

    let promise = new Promise((resolve) => {

        if (searchString.length < 2) {
            applyFilter();
            resolve(false)
            return
        }

        let lang = language.getApiCode();

        fetch('/api/product_search?lang='+lang+'&search='+encodeURIComponent(searchString),{
            method: 'GET'
        }).then(
            response => response.json()
        ).then(data => {
            // Process the data returned from the API
            //allProducts = data;
            searchProducts = []
            data.forEach((productID) => {
                searchProducts.push(productID)
            })
            applyFilter();
            resolve(true);
        }).catch(error => {
            // Handle any errors that occur during the fetch
            console.error('Error:', error);
            resolve(false);
        });

    })

    loading.push(promise)

}

async function getPDF ( id?:string, failOnMissing?:boolean ) {

    id = id || filteredProducts.selected
    failOnMissing = failOnMissing || false

    await loaded();
    
    if (typeof allPDFs[id] === 'undefined' ) {
        if (failOnMissing) {
            return null;
        }
        loadPDF([id]);
        return await getPDF( id, true );
    }

    return allPDFs[id]

}

function get_product ( id:string ) {

    if (typeof allProducts[id] === 'undefined' ) {
        console.error('there is no product with ID:'+id)
        return null;
    }

    return allProducts[id]

}

function getName( id?:string ) {

    id = id || filteredProducts.selected

    if (typeof allProducts[id] === 'undefined' ) {
        console.error('there is no product with ID:'+id)
        return '-'
    }

    return allProducts[id].name

}

function getURL( id?:string ) {

    if (typeof allProducts[id] === 'undefined' ) {
        console.error('there is no product with ID:'+id)
        return '/'
    }

    let categoryID = categories.getProductCategory(id);
    let path = categories.getCategoryPath(categoryID);
    path.push(id)
    
    return location.getURL(path);

}

function getAllAttributes() {

    console.info('productStore:getAllAttributes')

    let attributes = []

    Object.entries(allProducts).every(([prodID, product]) => {
        attributes.push(...product['attributes'])
        return true
    })

    return attributes.filter((value, index, array) => array.indexOf(value) === index);

}

function reset() {

    console.info('productStore:reset')

    categoryProducts = []
    attributeProducts = undefined
    searchProducts = undefined
    filteredProducts.selected = null
    filteredProducts.list = []
    filteredProducts.search = null
    update();

}

async function select( id:string, failOnMissing = false ) {

    await loaded()

    if (typeof allProducts[id] === 'undefined') {
        if (failOnMissing) {
            return false;
        }
        await load([id])
        return await select(id,true) // set failOnMissing to true to prevent infinite loading loop
    }
    
    filteredProducts.selected = id
    update()
    return true;

}

function create() {

    console.info('productStore:create')

    const { subscribe } = readable(filteredProducts, set => {
        update = () => set(filteredProducts);
    });

	return {
        subscribe,
        setCategoryFilter,
        setAttributeFilter,
		reset,
        select,
        get_product,
        getName,
        getPDF,
        getAllAttributes,
        applyFilter,
        search,
        getURL,
        loadMissingProducts,
        loadMissingPDFs,
        preLoadImage,
        preLoadPDFs,
        loaded
	};
}

export const products = create()