import { readable } from "svelte/store"
import { pwa } from "./pwaStore";
import { language } from "./languageStore";

let pageData = {
    pages:  {},
    selected: {} ,
    isSelected: false,
    is404: false
}

let init = false;
let initPromise:Promise<{}>

let update = () => {}

function loadList( callback?: Function ) {

    console.info('pageStore:loadList');

    initPromise = new Promise((resolve) => {

        let getParams = []
        getParams.push('filter={"lang":"'+language.getSlug()+'"}')
        getParams.push('projection=content')
        if (pwa.isOffline()) {
            getParams.push('pwa=1')
        }

        fetch('/api/page?'+getParams.join('&'),{
            method: 'GET'
        }).then(
            response => response.json()
        ).then(data => {
            // Process the data returned from the API
            data.every((page) => {
                if (typeof pageData.pages[page.lang] === 'undefined') {
                    pageData.pages[page.lang] = {}
                }
                pageData.pages[page.lang][page.slug] = page
                return true
            })
            if (typeof callback === 'function') {
                callback();
            }
            update();
            console.info('pageStore:loadList','success');
            resolve(true);
        }).catch(error => {
            // Handle any errors that occur during the fetch
            console.error('Error:', error);
            resolve(false);
        });
    });

}

function loadPage ( id:string, callback?: Function ) {

    console.info('pageStore:loadPage', id);

    return loaded(callback)

}

function loaded (callback?:Function) {

    if (!init) {
        init = true
        loadList()
    }

    callback = callback || (() => { return true })

    return new Promise((resolve) => {
        initPromise.then(() => {
            console.info('pageStore:loaded')
            resolve(callback());
        })
    })

}

function getTitle() {

    if (pageData.is404) {
        return '404: Page not Found'
    }

    if (pageData.isSelected) {
        return pageData.selected['title'] || ''
    }

    return ''

}

function exists( slug:string, lang:string ) {

    if (typeof pageData.pages[lang] === 'undefined' || typeof pageData.pages[lang][slug] === 'undefined') {
        return false
    }

    return true

}

async function select ( slug:string, lang:string ) {

    console.info('pageStore:select', slug, lang);

    return await loaded(() => { // wait for pagelist to be loaded

        if (typeof pageData.pages[lang] === 'undefined' || typeof pageData.pages[lang][slug] === 'undefined') {
            console.warn('pageStore:select', 'page does not exists', slug, lang)
            pageData.selected = {}
            pageData.isSelected = true;
            pageData.is404 = true;
            return false;
        }

        pageData.selected = pageData.pages[lang][slug]
        pageData.isSelected = true;
        update()

        if (typeof pageData.selected['content'] === 'undefined') {
            loadPage(pageData.selected['id'])
        }

        return true

    })

}

function reset () {

    pageData.selected = {}
    pageData.isSelected = false;
    pageData.is404 = false;
    update()

}

function do404 () {

    pageData.selected = {}
    pageData.isSelected = true;
    pageData.is404 = true;
    update()

}

function create() {

    console.info('pageStore:create')

    const { subscribe } = readable(pageData, set => {
        update = () => set(pageData);
    });

	return {
        subscribe,
        loaded,
        select,
        reset,
        exists,
        do404,
        getTitle
	};
}

export const page = create()