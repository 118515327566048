<script lang="ts">

    import { page } from "../stores/pageStore";
    import Loading from "./Loading.svelte"

</script>

<div class="wysiwyg page-content">
    {#if $page.selected['content']}
        {@html $page.selected['content']}
    {:else}
        {#if $page.is404}
            <h1>404</h1>
        {:else}
            <Loading />
        {/if}
    {/if}
</div>