<script lang="ts">
    
    import { attributes } from '../stores/attributeStore'
    import { products } from '../stores/productStore'
    import { pwa } from '../stores/pwaStore'
    import { themeimage } from '../stores/themeimageStore'
    import I18nString from './I18nString.svelte'
    import Loading from './Loading.svelte'
    import ProductDetailPdf from './ProductDetailPDF.svelte'
    import Productlist from './Productlist.svelte'
    import ThemeImage from './ThemeImage.svelte'

</script>

{#if $products.selected}
    {@const product = products.get_product($products.selected)}
    <h2 class="product-detail-name" itemprop="name">{product.name}</h2>
    <h6 class="product-detail-sku" itemprop="sku" content={product.sku}><I18nString slug="product-detail-sku" replace={{sku:product.sku}} /></h6>
    <div class="grid product-detail-grid">
        <div class="col-md-5 col-12">
            <div class="product-detail-image-container">
                    {#if product.image.large}
                        {#if pwa.isOffline()}
                            <img src="{product.image.large}?pwa=1" alt={product.name}/>
                            <a href={product.image.full} target="_blank"><i class="far fa-search"></i></a>
                        {:else}
                            <img src={product.image.large} alt={product.name}/>
                            <a href={product.image.full} target="_blank"><i class="far fa-search"></i></a>
                        {/if}
                    {:else}
                        <ThemeImage slug='product-image-missing' class="product-image-missing" />
                    {/if}
            </div>
        </div>
        <div class="col-md-7 col-12 product-detail-attributes">
            <h4><I18nString slug="product-detail-attributes" /></h4>
            {#await attributes.get_attributesByValueIDs(product.attributes)}
                <Loading />
            {:then productAttrGroups}
                <ul class="product-detail-attributes-list">
                    {#each productAttrGroups as group}
                        <li class="product-detail-attributes-group">
                            <span>{group.name}</span>
                            <ul>
                                {#each group.attributes as attr }
                                    <li>
                                        <i class="far fa-long-arrow-right"></i>
                                        {#if !attr.isLabelHidden}
                                            {attr.name}:
                                        {/if}
                                        {attr.value}
                                        {attr.unit?attr.unit:''}
                                    </li>
                                {/each}
                            </ul>
                        </li>
                    {/each}
                </ul>
                {#if false}
                    <p class="product-detail-text">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.
                    </p>
                {/if}
                <ProductDetailPdf />
                <p><a href="https://www.premiumcuttingsolutions.com/kontakt" target="_blank" class="btn btn-blue text-uppercase"><I18nString slug="product-detail-requestproduct" /> <i class="fal fa-user"></i></a></p>
            {/await}
        </div>
    </div>
    <h3 class="product-detail-similarproducts"><I18nString slug="product-detail-similarproducts" /></h3>
    <Productlist hideFilter={true} />
    {#if false}
        <div class="grid product-detail-downloads">
            <div class="col-6">
                <a href="#" class="product-detail-downloads-card">
                    <div>
                        <div class="product-detail-downloads-card-icon">
                            <i class="fal fa-file-pdf"></i>
                        </div>
                    </div>
                    <div class="product-detail-downloads-card-text">
                        <h4>MWS Produktkatalog Landwirtschaft</h4>
                        <p>Lorem ipsum dolor sit amet elitr.<i class="far fa-long-arrow-down"></i></p>
                    </div>
                </a>
            </div>
            <div class="col-6">
                <a href="#" class="product-detail-downloads-card">
                    <div>
                        <div class="product-detail-downloads-card-icon">
                            <i class="fal fa-file-pdf"></i>
                        </div>
                    </div>
                    <div class="product-detail-downloads-card-text">
                        <h4>MWS Produktkatalog Landwirtschaft</h4>
                        <p>Lorem ipsum dolor sit amet elitr.<i class="far fa-long-arrow-down"></i></p>
                    </div>
                </a>
            </div>
        </div>
    {/if}
{/if}

<style>
    .product-detail-name {
        margin-bottom: 0.6em;
    }
    .product-detail-sku {
        margin: 0;
    }
    .product-detail-grid {
        margin-left: -30px;
        margin-right: -30px;
    }
    .product-detail-grid > [class*="col-"] {
        padding: 30px;
    }
    .product-detail-image-container {
        position: sticky;
        top: 2em;
        width: 100%;
        padding-top: 100%;
        border: 2px solid #a9abac;
        background-color: #aaa;
    }
    .product-detail-image-container :global(img) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        background-color: #fff;
    }
    .product-detail-image-container :global(img.product-image-missing) {
        background-color: #051232;
        padding: 25%;
    }
    .product-detail-image-container a {
        position: absolute;
        font-size: 1.2em;
        line-height: 1;
        bottom: 1em;
        right: 1em;
        padding: 0.5em;
        border-radius: 50%;
        border: 3px solid #003057;
        color: #003057;
    }
    .product-detail-attributes h4 {
        margin-top: 0;
        margin-bottom: 1rem;
        line-height: 1em;
    }
    .product-detail-attributes-list {
        display: flex;
        flex-flow: column;
        margin: 0;
        padding: 0;
        list-style: none;
        margin-bottom: 1.5em;
    }
    .product-detail-attributes-group {
        display: flex;
        flex-flow: row wrap;
        border-top: 1px solid #dee2e6;
        padding: 1em;
    }
    .product-detail-attributes-group > span {
        font-weight: 700;
        display: block;
        flex: 0 0 50%
    }
    .product-detail-attributes-group > ul {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        flex: 0 0 50%;
        display: flex;
        flex-flow: column;
        gap: 0.5em;
    }
    .product-detail-attributes-group li {
        padding-left: 2em;
        color: #212529;
    }
    .product-detail-attributes-group li i {
        margin-left: -2em;
        margin-right: 1em;
        color: #003057;
    }
    .product-detail-text {
        color: #212529;
        margin-bottom: 1.5em;
    }
    .product-detail-similarproducts {
        margin-bottom: 0.25em;
    }
    .product-detail-downloads {
        padding-bottom: 3em;
    }
    .product-detail-downloads-card {
        padding: 1.5em;
        background-color: #c4c7c8;
        color: #003057;
        display: flex;
        flex-flow: row nowrap;
        gap: 1em;
        text-decoration: none;
        align-items: center;
    }
    .product-detail-downloads-card-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.6875em;
        height: 1.6875em;
        font-size: 2em;
        border: 3px solid #003057;
        border-radius: 50%;
    }
    .product-detail-downloads-card-text {
        display: flex;
        flex-flow: column;
        gap: 0.5em;
    }
    .product-detail-downloads-card-text h4 {
        margin: 0;
    }
    .product-detail-downloads-card-text p {
        margin: 0;
        color: #4d4d4d;
    }
    .product-detail-downloads-card-text p i {
        color: #007de3;
        margin-left: 1em;
    }
</style>