<script lang="ts">
    import { pwa } from "../stores/pwaStore"


    import { themeimage } from "../stores/themeimageStore";

    export let slug:string
    export let size:string = ''

    let src:string = ''

    if (typeof $themeimage.list[slug] !== 'undefined' ) {
        src = $themeimage.list[slug].url
    }
    
    if (pwa.isOffline()) { // only one size in offline mode
        src += '?pwa=1'
    } else if (size) {
        src += '?filter='+size
    }

</script>

<img {src} alt={$$props.alt} class={$$props.class} data-slug={slug} />