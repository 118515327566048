<script lang="ts">
    
    import { products } from '../stores/productStore';
    import { pwa } from '../stores/pwaStore'
    import I18nString from './I18nString.svelte'
    import Loading from './Loading.svelte'

    let pdfOpen = false;

    function togglePDF () {
        pdfOpen = !pdfOpen
    }

</script>

{#await products.getPDF()}
    <Loading />
{:then pdfURL} 
    {#if pdfURL}
        <p><a href={pdfURL} target="_blank" class="btn btn-blue text-uppercase" on:click|preventDefault="{togglePDF}"><I18nString slug="product-detail-drawing" /> <i class="fal fa-file-pdf"></i></a></p>
        {#if pdfOpen}
            <div class="object-container" on:click="{togglePDF}">
                {#if pwa.isOffline()}
                    <object data="{pdfURL}?pwa=1" type="application/pdf" title="product-detail-drawing" width="90vw" height="90vh">
                        <a href={pdfURL} target="_blank"><I18nString slug="product-detail-drawing" /></a>
                    </object>
                {:else}
                    <object data={pdfURL} type="application/pdf" title="product-detail-drawing" width="90vw" height="90vh">
                        <a href={pdfURL} target="_blank"><I18nString slug="product-detail-drawing" /></a>
                    </object>
                {/if}
                <i class="fal fa-times-circle"></i>
                </div>
        {/if}
    {/if}
{/await}

<style>
    .object-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000a;
        z-index: 999998;
        display: flex;
    }
    .object-container i {
        position: absolute;
        top: 1em;
        right: 1em;
        font-size: 2em;
        color: #fff;
        cursor: pointer;
        z-index: 999999;
    }
    object {
        width: 90vw;
        height: 90vh;
        margin: auto;
    }
</style>