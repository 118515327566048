import { readable, get } from "svelte/store"
import { products } from "./productStore"
import { i18n } from "./i18nStore"
import { language } from "./languageStore"
import { pwa } from "./pwaStore"

let filteredCategories = {
    loading: true,
    list:  [],
    selected: null
}

let init = false;
let initPromise: Promise<{}>

let allCategories = {}

let update = () => {}

function load( callback?: Function ) {

    console.info('categoryStore:load');

    initPromise = new Promise((resolve) => {

        let getParams = []
        getParams.push('lang='+language.getApiCode())
        if (pwa.isOffline()) {
            getParams.push('pwa=1')
        }

        fetch('/api/category?'+getParams.join('&'),{
            method: 'GET'
        }).then(
            response => response.json()
        ).then(data => {
            // Process the data returned from the API
            //categories = data;
            data.forEach((cat) => {
                allCategories[cat.id] = cat
            })
            if (typeof callback === 'function') {
                callback();
            }
            applyFilter();
            resolve(true);
        }).catch(error => {
            // Handle any errors that occur during the fetch
            console.error('Error:', error);
            resolve(false);
        });
    });

}

function loaded (callback?:Function) {

    if (!init) {
        init = true
        load();
    }

    callback = callback || (() => { return true })

    return new Promise((resolve) => {
        initPromise.then(() => {
            console.info('categoryStore:loaded');
            resolve(callback());
        })
    })

}

function applyFilter ( ) {

    console.info('categoryStore:applyFilter')
    
    filteredCategories.list = [];
    filteredCategories.loading = true;
    update()

    Object.entries(allCategories).every((cat) => {

        if (cat[1]['parent'] === filteredCategories.selected) {
            filteredCategories.list.push(cat[1]);
            return true;
        }

        return true;
    })

    filteredCategories.list.sort((a,b) => a.sortOrder - b.sortOrder);
    
    filteredCategories.loading = false
    update()

}

function get_products( id?:string ) {

    id = id || filteredCategories.selected

    if (typeof allCategories[id] === 'undefined' ) { // if category does not exist return empty array
        return [];
    }
    
    return allCategories[id].products

}

function get_category( id?:string ) {

    id = id || filteredCategories.selected

    if (typeof allCategories[id] === 'undefined' ) { // if category does not exist return null
        return null;
    }

    return allCategories[id]

}

function get_MainCats( primary?:boolean ) {

    primary = primary || true

    let cats = []

    Object.entries(allCategories).every((cat) => {

        if (cat[1]['parent'] === null && cat[1]['isPrimary'] === primary) {
            cats.push(cat[1]);
            return true;
        }

        return true;
    })

    cats.sort((a,b) => a.sortOrder - b.sortOrder);

    return cats;

}

function hasChildren( id?:string ) {

    id = id || filteredCategories.selected

    if (typeof allCategories[id] === 'undefined' ) { // if category does not exist return null
        return false;
    }

    return !!allCategories[id]['children'].length

}

function get_path( id?:string ) {

    id = id || filteredCategories.selected

    let path = []

    while (typeof allCategories[id] !== 'undefined') {
        path.unshift(id);
        id = allCategories[id]['parent'];
    }

    return path

}

function get_parent( id?:string ) {

    id = id || filteredCategories.selected

    if (typeof allCategories[id] !== 'undefined') {
        return allCategories[id]['parent'];
    }

    return null;

}

function get_name( id:string, lang?:string ) {

    if (typeof allCategories[id] !== 'undefined') {
        return allCategories[id]['name'];
    }

    return id;

}

async function select( id:string, applyProductFilter = false ) {

    return await loaded(() => { // wait for categories to be loaded

        if (typeof allCategories[id] === 'undefined' ) { // if category does not exist return false
            return false;
        }

        filteredCategories.selected = id
        applyFilter()
        products.setCategoryFilter(collectCategoryProducts(id))
        if (applyProductFilter) {
            products.applyFilter();
        }

        return true

    })

}

function collectAllProducts () {

    console.info('categoryStore:collectAllProducts')

    let products = []

    Object.entries(allCategories).every(([catID, cat]) => {

        products.push(...cat['products'])

        return true;

    })

    return products.filter((value, index, array) => array.indexOf(value) === index);

}

function collectCategoryProducts ( catID:string ) {
    
    console.info('collectCategoryProducts', catID)

    let products = []

    if (typeof allCategories[catID] === 'undefined' ) { // if category does not exist return false
        return products
    }

    products.push(...allCategories[catID].products)

    console.debug('collectCategoryProducts:myProducts', catID, products.length)

    allCategories[catID]['children'].every((subcatID) => {
        products.push(...collectCategoryProducts(subcatID))
        return true;
    })

    console.debug('collectCategoryProducts:allProducts', catID, products.length)

    return products.filter((value, index, array) => array.indexOf(value) === index);

}

function getProductCategory ( productID:string ) {

    let categoryID = null;

    Object.entries(allCategories).every(([catID, cat]) => {

        if (cat['products'].includes(productID)) {
            categoryID = catID
            return false;
        }

        return true;
    })

    return categoryID

}

function getCategoryPath ( catgeoryID:string ) {

    let path = [];

    if (allCategories[catgeoryID]['parent'] === null) {
        path.push(catgeoryID)
    } else {
        path = getCategoryPath(allCategories[catgeoryID]['parent'])
        path.push(catgeoryID)
    }

    return path;

}

function reset() {

    filteredCategories.selected = null
    applyFilter()

}

function create() {

    console.info('categoryStore:create')

    const { subscribe } = readable(filteredCategories, set => {
        update = () => set(filteredCategories);
    });

	return {
        subscribe,
        loaded,
        select,
        reset,
        get_products,
        get_category,
        hasChildren,
        get_path,
        get_MainCats,
        get_parent,
        get_name,
        collectAllProducts,
        collectCategoryProducts,
        getProductCategory,
        getCategoryPath
	};
}

export const categories = create()