<script lang="ts">
    
    import { categories } from '../stores/categoryStore';
    import { i18n } from '../stores/i18nStore'
    import { language } from '../stores/languageStore'
    import { location } from '../stores/locationStore';
    import Themeimage from './ThemeImage.svelte'
    import I18nText from './I18nText.svelte';

</script>

<div class="grid cattext">
    <div class="col-sm-9 col-12">
        <I18nText slug='cattext-maincat' />
    </div>
</div>
<div class="grid catlist">
    {#each $categories.list as cat}
        {#if cat.isPrimary === true}
            <div class="col-lg-4 col-sm-6 col-12">
                <div class="catlist-cat-container bg-blue cat-primary">
                    <div class="bg-image bg-image-dark">
                        <Themeimage slug='catimg-{cat.id}' />
                    </div>
                    <a href="{location.getURL([cat.id])}" class="catlist-cat text-white" on:click|preventDefault={() => location.pushState(location.getURL([cat.id]))}>
                        <h3>{cat.name}</h3>
                        <span class="catlink-button">{i18n.getString('catlist-button-text')} <span class="far fa-long-arrow-right"></span></span>
                    </a>   
                </div>
            </div>
        {/if}
    {/each}
    <div class="col-12 newline"></div>
    {#each $categories.list as cat}
        {#if cat.isPrimary === false}
            <div class="col-md-4 col-xs-6 col-12">
                <div class="catlist-cat-container bg-blue cat-secundary">
                    <div class="bg-image bg-image-dark">
                        <Themeimage slug='catimg-{cat.id}' />
                    </div>
                    <a href="{location.getURL([cat.id])}" class="catlist-cat text-white" on:click|preventDefault={() => location.pushState(location.getURL([cat.id]))}>
                        <h3>{cat.name}</h3>
                        <span class="catlink-arrow"><span class="far fa-long-arrow-right"></span></span>
                    </a>   
                </div>
            </div>
        {/if}
    {/each}
</div>

<style>
    .cattext :global(h6) {
        margin: 0;
    }
    .cattext :global(h2) {
        margin-top: 0.5em;
    }
    .catlist-cat-container {
        position: relative;
        padding-top: 66%;
    }
    .catlist-cat-container.cat-secundary {
        padding-top: 0;
    }
    .catlist-cat {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 3em;
        text-decoration: none;
    }
    .catlist-cat h3 {
        margin-top: 0;
    }
    .cat-secundary .catlist-cat {
        position: relative;
        padding: 2em;
        text-decoration: none;
    }
    .cat-secundary .catlist-cat h3 {
        margin-bottom: 0;
        font-size: 25px;
    }
    .catlist .newline {
        padding: 0;
    }
    .catlink-button {
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: 100%;
        padding: 1em 2em 1em 3em;
        background-color: #003057;
    }
    .catlink-button span {
        margin-left: 1em;
    }
    .catlink-arrow {
        position: absolute;
        top: 50%;
        right: 1em;
        transform: translate(0, -50%);
        font-size: 1.5em;
    }
</style>