import App from "./components/App.svelte"
import { location } from "./stores/locationStore"

if (typeof history !== "undefined") {
    if (typeof Proxy !== "undefined") {
        // modern browser
        const historyApply = (target, thisArg, argumentsList) => {
            location.publishLocation(
                argumentsList && argumentsList.length >= 2 && argumentsList[2]
            )
            Reflect.apply(target, thisArg, argumentsList)
        }

        history.pushState = new Proxy(history.pushState, {
            apply: historyApply,
        })

        history.replaceState = new Proxy(history.replaceState, {
            apply: historyApply,
        })
    } else {
        // ie11
        const pushStateFn = history.pushState
        const replaceStateFn = history.replaceState

        history.pushState = function (data: any, title: string, url?: string) {
            location.publishLocation(url)
            return pushStateFn.apply(history, arguments)
        }
        history.replaceState = function (
            data: any,
            title: string,
            url?: string
        ) {
            location.publishLocation(url)
            return replaceStateFn.apply(history, arguments)
        }
    }
} // else ssr -> no history handling

typeof window !== "undefined" &&
    window.addEventListener("popstate", (event) => {
        location.publishLocation()
    })

let appContainer = document?.getElementById("appContainer")

const hydrate = true //import.meta?.env?.MODE !== "development"
console.debug("Features: ", { hydrate })
const app = new App({
    target: appContainer,
    props: {},
    hydrate,
})

export default app