import { readable, get } from "svelte/store"
import { language } from "./languageStore";
import { pwa } from "./pwaStore";

let i18nData = {
    loading: true,
    text:  {},
    string: {},
}

let init = false;
let loading = []

let update = () => {}

function loadText( callback?: Function ) {

    console.info('i18nStore:loadText');

    let promise = new Promise((resolve) => {

        let getParams = []
        getParams.push('filter={"lang":"'+language.getSlug()+'"}')
        if (pwa.isOffline()) {
            getParams.push('pwa=1')
        }

        fetch('/api/text_i18n?'+getParams.join('&'),{
            method: 'GET'
        }).then(
            response => response.json()
        ).then(data => {
            // Process the data returned from the API
            data.every((entry) => {
                if (typeof i18nData.text[entry.lang] === 'undefined') {
                    i18nData.text[entry.lang] = {}
                }
                i18nData.text[entry.lang][entry.slug] = entry.text
                return true
            })
            if (typeof callback === 'function') {
                callback();
            }
            update();
            console.info('i18nStore:loadText','success');
            resolve(true);
        }).catch(error => {
            // Handle any errors that occur during the fetch
            console.error('Error:', error);
            resolve(false);
        });
    });

    loading.push(promise)

}

function loadString( callback?: Function ) {

    console.info('i18nStore:loadString');

    let promise = new Promise((resolve) => {

        let getParams = []
        getParams.push('filter={"lang":"'+language.getSlug()+'"}')
        if (pwa.isOffline()) {
            getParams.push('pwa=1')
        }

        fetch('/api/string_i18n?'+getParams.join('&'),{
            method: 'GET'
        }).then(
            response => response.json()
        ).then(data => {
            // Process the data returned from the API
            data.every((entry) => {
                if (typeof i18nData.string[entry.lang] === 'undefined') {
                    i18nData.string[entry.lang] = {}
                }
                i18nData.string[entry.lang][entry.slug] = entry.string
                return true
            })
            if (typeof callback === 'function') {
                callback();
            }
            update();
            console.info('i18nStore:loadString','success');
            resolve(true);
        }).catch(error => {
            // Handle any errors that occur during the fetch
            console.error('Error:', error);
            resolve(false);
        });
    });

    loading.push(promise)

}

function loaded (callback?:Function) {

    if (!init) {
        init = true
        loadText()
        loadString()
    }

    callback = callback || (() => { return true })

    return new Promise((resolve) => {

        Promise.allSettled(loading).then(() => {
            console.info('i18nStore:loaded')
            resolve(callback());
        });

    });

}

function getText( slug:string, lang?:string, replace?:{ [key: string]: string; } ) {

    lang = lang || get(language).selected.slug;
    replace = replace || {};

    if (typeof i18nData.text[lang] === 'undefined') {
        console.debug('i18nStore:getText',slug,lang,'lang not defined')
        return lang+'_'+slug;
    }

    if (typeof i18nData.text[lang][slug] === 'undefined') {
        console.debug('i18nStore:getText',slug,lang,'slug not defined')
        return lang+'_'+slug;
    }

    return doPlaceholder(i18nData.text[lang][slug], replace)

}

function getString( slug:string, lang?:string, replace?:{ [key: string]: string; } ) {

    lang = lang || get(language).selected.slug;
    replace = replace || {};

    if (typeof i18nData.string[lang] === 'undefined') {
        console.debug('i18nStore:getString',slug,lang,'lang not defined')
        return lang+'_'+slug;
    }

    if (typeof i18nData.string[lang][slug] === 'undefined') {
        console.debug('i18nStore:getString',slug,lang,'slug not defined')
        return lang+'_'+slug;
    }

    return doPlaceholder(i18nData.string[lang][slug], replace)

}

function doPlaceholder ( text:string, replace:{ [key: string]: string; }) {

    Object.keys(replace).every((search) => {
        text = text.replaceAll('{{'+search+'}}',replace[search])
        return true
    })

    return text

}

function create() {

    console.info('i18nStore:create')

    const { subscribe } = readable(i18nData, set => {
        update = () => set(i18nData);
    });

	return {
        subscribe,
        loaded,
        getText,
        getString,
	};
}

export const i18n = create()