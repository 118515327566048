<script lang="ts">
    
    import { products } from '../stores/productStore';
    import { categories } from '../stores/categoryStore';
    import { location } from '../stores/locationStore';
    import Loading from './Loading.svelte';
    import ProductFilter from './ProductFilter.svelte';
    import I18nString from './I18nString.svelte'
    import { attributes } from '../stores/attributeStore'
    import ThemeImage from './ThemeImage.svelte'
    import { pwa } from '../stores/pwaStore'

    export let hideFilter = false;

</script>

{#await products.applyFilter()}
    <Loading />
{:then wayne}
    {#if !hideFilter}
        <ProductFilter />
    {/if}
    <div class="productlist">
        <div class="grid">
            {#each $products.list as product}
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <a href="{products.getURL(product.id)}" id={product.id} class="productlist-productcard" on:click|preventDefault={() => location.pushState(products.getURL(product.id))}>
                        <div class="productlist-productcard-image-container">
                            <div class="productlist-productcard-image-wrapper">
                                {#if product.image.small}
                                    {#if pwa.isOffline()}
                                        <img src="{product.image.small}?pwa=1" alt={product.name}/>
                                    {:else}
                                        <img src={product.image.small} alt={product.name}/>
                                    {/if}
                                {:else}
                                    <ThemeImage slug='product-image-missing' class="product-image-missing" />
                                {/if}
                            </div>
                        </div>
                        <div class="productlist-productcard-name"><h3>{product.name}</h3></div>
                        <div class="productlist-productcard-sku"><I18nString slug="product-list-sku" replace={{sku:product.sku}} /></div>
                        {#await attributes.loaded()}
                            <div>-</div>
                            <div>-</div>
                        {:then wayne}
                            <div class="productlist-productcard-manufacturer"><I18nString slug="product-list-manufacturer" replace={{manufacturer:attributes.getProductFilterAttributeValue('63f65f4c149ac9cc7',product.id)}} /></div>
                            <div class="productlist-productcard-reference"><I18nString slug="product-list-reference" replace={{reference:attributes.getProductFilterAttributeValue('63f65fa8b29fadecb',product.id)}} /></div>
                        {/await}
                    </a>
                </div>
            {/each}
        </div>
    </div>
{/await}

<style>
    .productlist {
        padding-bottom: 3em;
    }
    .productlist-productcard {
        display: flex;
        flex-flow: column;
        background-color: #ebeef1;
        padding: 1.5em;
        color: #003057;
        text-decoration: none;
        height: 100%;
    }
    .productlist-productcard h3 {
        font-size: 1.125em;
        margin-bottom: 0.25em;
    }
    .productlist-productcard-image-container {
        width: 125px;
        margin: 0 auto;
    }
    .productlist-productcard-image-wrapper {
        position: relative;
        padding-top: 100%;
        overflow: hidden;
        border-radius: 50%;
        border: 2px solid #a9abac;
        background-color: #fff;
        font-size: 0;
    }
    .productlist-productcard-image-wrapper :global(img) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
    .productlist-productcard-image-wrapper :global(img.product-image-missing) {
        background-color: #051232;
        padding: 10%;
    }
</style>