<script lang="ts">

    import { i18n } from "../stores/i18nStore";
    import { language } from "../stores/languageStore";
    import Themeimage from "./ThemeImage.svelte";

</script>

<section class="contactbanner bg-blue text-white">
    <div class="container">
        <div class="grid">
            <div class="col-sm-4 col-xs-6 col-12 contactbanner-image-col">
                <div class="contactbanner-image-container">
                    <Themeimage slug='contactbanner-image' />
                </div>
            </div>
            <div class="col-sm-8 col-12 contactbanner-text">
                <div class="wysiwyg">
                    {@html i18n.getText('contactbanner-text', $language.selected.slug)}
                </div>
            </div>
        </div>
    </div>
</section>

<style>
    .contactbanner {
        padding: 4em 0;
    }
    .contactbanner:last-child {
        padding-bottom: 0;
    }
    .contactbanner .grid {
        margin: 0 -2em;
    }
    .contactbanner [class*="col-"] {
        padding: 2em;
    }
    .contactbanner-image-col {
        display: flex;
        align-items: center;
    }
    .contactbanner-image-container {
        position: relative;
        padding-top: 100%;
        overflow: hidden;
        border-radius: 50%;
        width: 100%;
    }
    .contactbanner-image-container :global(img) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    .contactbanner-text {
        display: flex;
        align-items: center;
        font-size: 1.1em;
        font-weight: 300;
    }
    .contactbanner-text :global(p) {
        line-height: 1.4em;
    }
    .contactbanner-text :global(h6) {
        margin-top: 0;
        margin-bottom: 1em;
    }
    .contactbanner-text :global(h2) {
        margin-top: 0;
        font-weight: 300;
    }
    .contactbanner-text :global(a) {
        display: inline-block;
        color: inherit;
        font-weight: 500;
        border: 2px solid #fff;
        border-radius: 0;
        padding: 0.75em 2em;
        line-height: 1.5em;
        text-decoration: none;
        transition: all 0.2s ease-out;
        margin: 0.5em;
    }
    .contactbanner-text :global(a:hover) {
        background-color: #a9abac;
        border-color: #a9abac;
        color: #003057;
    }
    .contactbanner-text :global(a > span) {
        color: #007DE3;
        white-space: nowrap;
    }
    /* < xs */
    @media (max-width: 575px) {
        .contactbanner-text :global(a) {
            display: block;
            width: 100%;
            padding: 0.75em 1em;
        }
    }
</style>