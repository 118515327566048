<script lang="ts">

    import { location } from "../stores/locationStore";
    import { language } from "../stores/languageStore";

    export let path:string[] = []
    export let text:string = ''

</script>

<a href="{location.getURL(path, $language.selected.slug)}" on:click|preventDefault={() => location.pushState(location.getURL(path, $language.selected.slug))} class={$$props.class}>
    {@html text}
</a>

<style>
    a,
    a:active,
    a:visited {
        text-decoration: none;
        color: inherit;
        transition: color 0.2s ease-out;
    }
    a.link-hover:hover {
        color: #007de3;
    }
    a.link-uppercase {
        text-transform: uppercase;
    }
</style>