import { readable } from "svelte/store"
import { language } from "./languageStore"
import { pwa } from "./pwaStore";

let attributeData = {
    groups:  {},
    attributes: {},
    values: {},
    filter: {}
}

let init = false;
let loadedValues = []

let loading = []

let update = () => {}

function loadGroups () {

    console.info('attributeStore:loadGroups');

    let promise = new Promise((resolve) => {

        let getParams = []
        getParams.push('lang='+language.getApiCode())
        if (pwa.isOffline()) {
            getParams.push('pwa=1')
        }

        fetch('/api/attribute_group?'+getParams.join('&'),{
            method: 'GET'
        }).then(
            response => response.json()
        ).then(data => {
            // Process the data returned from the API
            data.forEach((group) => {
                attributeData.groups[group.id] = group
            })
            update()
            resolve(true)
        }).catch(error => {
            // Handle any errors that occur during the fetch
            console.error('Error:', error);
            resolve(false)
        });
    })

    loading.push(promise)

}

function loadAttributes () {

    console.info('attributeStore:loadAttributes');

    let promise = new Promise((resolve) => {

        let getParams = []
        getParams.push('lang='+language.getApiCode())
        if (pwa.isOffline()) {
            getParams.push('pwa=1')
        }

        fetch('/api/attribute?'+getParams.join('&'),{
            method: 'GET'
        }).then(
            response => response.json()
        ).then(data => {
            // Process the data returned from the API
            data.forEach((attr) => {
                attributeData.attributes[attr.id] = attr
            })
            update()
            resolve(true);
        }).catch(error => {
            // Handle any errors that occur during the fetch
            console.error('Error:', error);
            resolve(false);
        });
    });

    loading.push(promise)

}

function loadValues ( missingValues:Array<string> ) {

    console.info('attributeStore:loadValues');

    let valuesToLoad = []
    missingValues.every((valueID) => {
        if (loadedValues.indexOf(valueID) === -1) {
            valuesToLoad.push(valueID);
            loadedValues.push(valueID);
        }
        return true
    });

    let promise = new Promise((resolve) => {

        if (valuesToLoad.length === 0) {
            resolve(true);
            return
        }

        let getParams = []
        getParams.push('lang='+language.getApiCode())
        if (pwa.isOffline()) {
            getParams.push('pwa=1')
        }
        let attributeid = getAttributeIDs();

        fetch('/api/attribute_value?'+getParams.join('&')+'&in='+valuesToLoad.join(',')+'&attributeid='+attributeid.join(','),{
            method: 'GET'
        }).then(
            response => response.json()
        ).then(data => {
            // Process the data returned from the API
            data.forEach((value) => {
                attributeData.values[value.id] = value
            })
            update()
            resolve(true);
        }).catch(error => {
            // Handle any errors that occur during the fetch
            console.error('Error:', error);

            // remove Values from loading Values so they can be requested again later
            valuesToLoad.forEach((valueID) => {
                const index = loadedValues.indexOf(valueID);
                if (index > -1) { // only splice array when item is found
                    loadedValues.splice(index, 1);
                }
            })

            resolve(false);
        });
    })

    loading.push(promise)

    return promise

}

function loadValuesByAttribute ( attributeIDs:Array<string> ) {

    console.info('attributeStore:loadValoadValuesByAttributelues', attributeIDs);

    let promise = new Promise((resolve) => {

        if (attributeIDs.length === 0) {
            resolve(true);
            return
        }

        let getParams = []
        getParams.push('lang='+language.getApiCode())
        if (pwa.isOffline()) {
            getParams.push('pwa=1')
        }

        fetch('/api/attribute_value?'+getParams.join('&')+'&attributeid='+attributeIDs.join(','),{
            method: 'GET'
        }).then(
            response => response.json()
        ).then(data => {
            // Process the data returned from the API
            data.forEach((value) => {
                attributeData.values[value.id] = value
                loadedValues.push(value.id);
            })
            update()
            resolve(true);
        }).catch(error => {
            // Handle any errors that occur during the fetch
            console.error('Error:', error);

            resolve(false);
        });
    })

    loading.push(promise)

    return promise

}

function loadFilter () {

    console.info('attributeStore:loadFilter');

    let promise = new Promise((resolve) => {

        let getParams = []
        getParams.push('filter={"lang":"'+language.getSlug()+'"}')
        if (pwa.isOffline()) {
            getParams.push('pwa=1')
        }

        fetch('/api/attribute_filter?'+getParams.join('&'),{
            method: 'GET'
        }).then(
            response => response.json()
        ).then(data => {
            // Process the data returned from the API
            data.forEach((value) => {
                if (typeof attributeData.filter[value.attribute] === 'undefined') {
                    attributeData.filter[value.attribute] = {}
                }
                attributeData.filter[value.attribute][value.attributeValue] = value.attributeValueProducts
            })
            sortFilterAttributes();
            update()
            resolve(true);
        }).catch(error => {
            // Handle any errors that occur during the fetch
            console.error('Error:', error);
            resolve(false);
        });
    })

    loading.push(promise)

    return promise

}

function loaded (callback?:Function) {

    if (!init) {
        init = true
        loadGroups()
        loadAttributes()
        loadFilter()
    }

    callback = callback || (() => { return true })

    return new Promise((resolve) => {

        Promise.allSettled(loading).then(() => {
            console.info('attributeStore:loaded')
            resolve(callback());
        });

    });

}

function loadMissingValuesByAttribute ( attributeIDs: Array<string> ) {

    console.info('attributeStore:loadAllValuesByAttribute');

    attributeIDs = attributeIDs.slice(0)

    let promise = new Promise((resolve) => {
        let attributeToLoad = attributeIDs.slice(0, 1)
        attributeIDs.splice(0, 1)
        loadValuesByAttribute(attributeToLoad).then(() => {
            if (attributeIDs.length) { // more values to load
                loadMissingValuesByAttribute(attributeIDs).then(()=>{
                    resolve(true)
                })
            } else { // all products loaded
                resolve(true)
            }
        })
    })

    loading.push(promise)

    return promise;

}

function loadMissingValues ( missingValues: Array<string> ) {

    console.info('attributeStore:loadMissingValues', missingValues);

    missingValues = missingValues.slice(0)

    let n = 150;

    let promise = new Promise((resolve) => {
        let valuesToLoad = missingValues.slice(0, n)
        missingValues.splice(0, n)
        loadValues(valuesToLoad).then(() => {
            if (missingValues.length) { // more values to load
                loadMissingValues(missingValues).then(()=>{
                    resolve(true)
                })
            } else { // all products loaded
                resolve(true)
            }
        })
    })

    loading.push(promise)

    return promise;

}

async function get_attributesByValueIDs ( valueIDs: Array<string> ) {

    console.info('attributeStore:get_attributesByValueIDs', valueIDs);

    await loaded();

    let missingValues = []

    valueIDs.every((valueID) => {
        if (typeof attributeData.values[valueID] === 'undefined') {
            missingValues.push(valueID)
        }
        return true;
    })

    if (missingValues.length) {
        loadMissingValues(missingValues);
    }

    await loaded();

    console.info('attributeStore:get_attributesByValueIDs:loaded');

    let data = {}
    valueIDs.every((valueID) => {
        let attributeID = null;
        let groupID = null;
        let group = null;
        try {
            attributeID = attributeData.values[valueID].attribute
            groupID = attributeData.attributes[attributeID].group
            group = attributeData.groups[groupID]
            if (typeof group === 'undefined') {
                return true
            }
        } catch (ex) {
            return true; // next
        }

        if (attributeData.attributes[attributeID].isHidden === true) {
            return true;
        }

        console.debug(valueID,attributeID,groupID,group);
        
        if (typeof data[groupID] === 'undefined') {
            data[groupID] = {
                id: group.id,
                name: group.name,
                attributes: []
            }
        }
        data[groupID].attributes.push({
            id: attributeData.attributes[attributeID].id,
            name: attributeData.attributes[attributeID].name,
            value: attributeData.values[valueID].value,
            unit: attributeData.values[valueID].unit,
            isLabelHidden: attributeData.attributes[attributeID].isLabelHidden,
        })
        return true;
    })

    let groups = []
    Object.entries(data).every((group) => {
        groups.push(group[1])
        return true
    })

    console.debug('attributeStore:get_attributesByValueIDs:groups',groups)

    return groups

}

async function getFilterAttributes () {

    console.info('attributeStore:getFilterAttributes');

    await loaded()

    let attributes = []

    for (let attributeID in attributeData.attributes) {
        if (attributeData.attributes[attributeID].isFilter === true) {
            if (typeof attributeData.filter[attributeID] !== 'undefined') {
                attributes.push({
                    name: attributeData.attributes[attributeID].name,
                    id: attributeData.attributes[attributeID].id,
                    values: attributeData.filter[attributeID]
                })
            }
        }
    }
    

    return attributes

}

function getProductFilterAttributeValue ( attributeID, productID ) {

    console.info('attributeStore:getProductFilterAttributeValue');

    let value = '-';

    let values = [];

    if (typeof attributeData.filter[attributeID] !== 'undefined') {
        Object.entries(attributeData.filter[attributeID]).every(([attributeValue, products]) => {
            if (Array.isArray(products) && products.includes(productID)) {
                values.push(attributeValue);
                return false;
            }
            return true
        })
        value = values.join('; ');
    } else {
        console.debug('no filter')
    }

    return value;

}

function getAttributeIDs ()  {

    let attributeIDs = [];

    for (let attributeID in attributeData.attributes) {
        attributeIDs.push(attributeID);
    }

    return attributeIDs;

}

function sortFilterAttributes() {

    Object.entries(attributeData.filter).every(([attributeID, unordered]) => {
        const ordered = Object.keys(unordered).sort((a, b) => {
            const nameA = (''+a).toUpperCase(); // ignore upper and lowercase
            const nameB = (''+b).toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          }).reduce((obj, key) => { 
                obj[key] = unordered[key]; 
                return obj;
            }, 
            {}
        );
        attributeData.filter[attributeID] = ordered
        return true
    })

}

function searchAttributeValue ( searchString:string ) {

    let attributeValueIDs = []

    if (searchString.length < 2) {
        return attributeValueIDs
    }

    const searchRegExp = new RegExp(searchString, "i");

    let i = 0

    Object.entries(attributeData.values).every(([valueID, data]) => {
        if ((data['value']+'').match(searchRegExp) !== null) {
            attributeValueIDs.push(valueID)
            i++;
        }
        if (i < 100)  {
            return true
        }
    })

    return attributeValueIDs

}

function create () {

    console.info('attributeStore:create')

    const { subscribe } = readable(attributeData, set => {
        update = () => set(attributeData);
    });

	return {
        subscribe,
        get_attributesByValueIDs,
        getFilterAttributes,
        getProductFilterAttributeValue,
        loadMissingValues,
        loadMissingValuesByAttribute,
        getAttributeIDs,
        searchAttributeValue,
        loaded
	};
}

export const attributes = create()