import { readable } from "svelte/store";
import { categories } from "./categoryStore";
import { products } from "./productStore";
import { attributes } from "./attributeStore";
import { themeimage } from "./themeimageStore";

let data = {
    offline: false,
    loaded: false,
    preloadImages : false
}

let update = () => {}
let loading = []
let init = false;

function checkOfflineFlag () {

    console.info('pwaStore:checkOfflineFlag')

    if (window.location.search.indexOf('pwa=offline') >= 0) {
        console.info('pwaStore:checkOfflineFlag', 'offline mode in URL');
        data.offline = true;
        data.preloadImages = true;
        localStorage.setItem('pwaOfflineMode', JSON.stringify(true))
        update()
        return
    }

    if (window.location.search.indexOf('pwa=online') >= 0) {
        console.info('pwaStore:checkOfflineFlag', 'online mode in URL');
        data.offline = false;
        localStorage.removeItem('pwaOfflineMode')
        update()
        return
    }

    const pwaOfflineMode = localStorage.getItem('pwaOfflineMode')
    if(pwaOfflineMode) {
        console.info('pwaStore:checkOfflineFlag', 'localStorage', pwaOfflineMode)
        data.offline = !!JSON.parse(pwaOfflineMode)
        update()
        return
    }

    console.info('pwaStore:checkOfflineFlag', 'online mode')

}

function loadOfflineData () {

    console.info('pwaStore:loadOfflineData')

    loading.push(new Promise((resolve) => {

        if (data.offline && !data.loaded) { // if offlinemode active

            categories.loaded().then(() => {
                let productList = categories.collectAllProducts().sort()
                products.loadMissingProducts(productList)
                products.loadMissingPDFs(productList)
                productList = [];
                products.loaded().then(() => { // wait till all products and pdf links are loaded
                    attributes.loaded().then(() => { // wait until all attributes, groups and filters are loaded
                        attributes.loadMissingValuesByAttribute(attributes.getAttributeIDs().sort())
                        attributes.loaded().then(() => { // wait for all attribute Values to be loaded
                            if (data.preloadImages) {
                                themeimage.preLoad().then(()=>{ // wait for all theme Images to be loaded
                                    products.preLoadImage().then(()=>{ //wait for all Product Images to be loaded
                                        products.preLoadPDFs().then(()=>{ //wait for all Product Images to be loaded
                                            data.loaded = true
                                            resolve(true)
                                        })
                                    })
                                })
                            } else {
                                data.loaded = true
                                resolve(true)
                            }
                        })
                    })
                })
            })

        } else {
            resolve(true)
        }
    
    }));

    

}

function isOffline () {

    return data.offline

}

function loaded () {

    if (!init) { // do stuff on 1st call
        init = true;
        checkOfflineFlag();
    }

    return new Promise((resolve) => {

        Promise.allSettled(loading).then(() => {
            console.info('pwaStore:loaded')
            resolve(true)
        });

    });

}

function register() {
    if ('serviceWorker' in navigator) {
        console.info("serviceWorker:register")
        navigator.serviceWorker.register('/serviceworker.js?23-11-09-15-50')
        .then((registration) => {
            console.log('Service Worker registered', registration);
        })
        .catch((error) => {
            console.error('Service Worker registration failed:', error);
        });
    }
}

function create() {

    console.info('pwaStore:create')

    const { subscribe } = readable(data, set => {
        update = () => set(data);
    });

    register();

	return {
        subscribe,
        loaded,
        loadOfflineData,
        isOffline
	};
}

export const pwa = create()