<script lang="ts">

    import { products } from '../stores/productStore';
    import { attributes } from "../stores/attributeStore"
    import Loading from "./Loading.svelte"
    import I18nString from "./I18nString.svelte"

    let filter = {}

    function intersect(A, B) {
        return A.filter(x => B.includes(x));
    }

    function isInProductList (filterProducts) {

        let intersection = intersect(filterProducts,$products.idList)

        if (intersection.length) {
            return true
        }

        return false
    }

    function filterProducts () {

        let attributeProducts;

        for (const [attributeID, attributeValue] of Object.entries(filter)) {
            if (attributeValue === '') {
                continue;
            }
            console.log('ProductFilter:filterProducts',attributeID,attributeValue)
            let productIDs = $attributes.filter[attributeID][attributeValue];
            if (typeof attributeProducts === 'undefined') {
                attributeProducts = productIDs
            } else {
                attributeProducts = intersect(attributeProducts,productIDs)
            }
        }

        products.setAttributeFilter(attributeProducts);
        products.applyFilter();

    }

    filterProducts();

</script>

<div class="productfilter">
    <div class="grid">
        {#await attributes.getFilterAttributes()}
            <Loading />
        {:then filterAttributes}
            {#key $products.idList}
                {#each filterAttributes as attr}
                    <div class="col-sm-auto col-12">
                        <div class="productfilter-filter">
                            <label for="filter_{attr.id}">{attr.name}</label>
                            <select id="filter_{attr.id}" bind:value={filter[attr.id]} on:change={filterProducts}>
                                <option value=""><I18nString slug="product-filter-select" /></option>
                                {#each Object.entries(attr.values) as [value, products]}
                                    {#if isInProductList(products)}
                                        <option value={value}>{value}</option>
                                    {/if}
                                {/each}
                            </select>
                        </div>
                    </div>
                {/each}
            {/key}
        {/await}
    </div>
</div>

<style>
    .productfilter {
        background-color: #ebeef1;
        margin-bottom: 15px;
    }
    .productfilter .grid {
        margin: 0;
    }
    .productfilter-filter {
        display: flex;
        flex-flow: column;
    }
    .productfilter-filter label {
        color: #003057;
        font-size: 0.75em;
        text-transform: uppercase;
        margin-bottom: 0.75em;
        margin-left: 0.5em;
    }
    .productfilter-filter select {
        padding: 1em 0.5em;
        line-height: 1em;
        border-radius: 0;
        border: 1px solid #ced4da;
        background-color: #fff;
    }
</style>
