<script lang="ts">
    
    import { i18n } from "../stores/i18nStore";
    import { language } from "../stores/languageStore";

    export let slug:string;
    

</script>

<div class="wysiwyg">
    {#await i18n.loaded()}
        ...
    {:then loaded}
        {@html i18n.getText(slug, $language.selected.slug)}
    {/await}
</div>