<script lang="ts">
    
    import { categories } from '../stores/categoryStore';
    import { location } from '../stores/locationStore';

</script>

<div class="grid subcattext">
    <div class="col-9">
        <h6>{ categories.get_name($categories.selected) }</h6>
    </div>
</div>
<div class="grid subcatlist">
    {#each $categories.list as cat}
        <div class="col-md-4 col-xs-6 col-12">
            <div class="subcatlist-cat-container bg-blue">
                <a href="{location.getURL([cat.id])}" class="subcatlist-cat text-white" on:click|preventDefault={() => location.pushState(location.getURL([cat.id]))}>
                    <h3>{cat.name}</h3>
                    <span class="catlink-arrow"><span class="far fa-long-arrow-right"></span></span>
                </a>   
            </div>
        </div>
    {/each}
</div>

<style>
    .subcattext :global(h6) {
        margin: 0;
    }
    .subcattext :global(h2) {
        margin-top: 0.5em;
    }
    .subcatlist-cat-container {
        position: relative;
    }
    .subcatlist-cat {
        display: block;
        position: relative;
        padding: 2em;
        text-decoration: none;
    }
    .subcatlist-cat h3 {
        margin: 0;
        font-size: 25px;
    }
    .catlink-arrow {
        position: absolute;
        top: 50%;
        right: 1em;
        transform: translate(0, -50%);
        font-size: 1.5em;
    }
    @media (max-width: 991px) {
        .subcatlist-cat {
            padding: 1.5em;
        }
    }
    @media (max-width: 767px) {
        .subcatlist-cat h3 {
            font-size: 20px;
        }  
    }
</style>