<script lang="ts">

    import { categories } from "../stores/categoryStore";
    import { i18n } from "../stores/i18nStore"
    import { language } from "../stores/languageStore"
    import { location } from "../stores/locationStore";
    import Link from "./Link.svelte"
    import Themeimage from "./ThemeImage.svelte"

    const year = new Date().getFullYear();

</script>

<section class="footer bg-blue text-white">
    <div class="container">
        <div class="grid">
            <div class="col-md-6 col-12">
                <div class="footer-logo-container">
                    <Themeimage slug='logo-white' alt='MWS' class='footer-logo' />
                </div>
                <div class="footer-address-container">
                    <p>
                        <i class="fal fa-map-marker-alt"></i> MWS Schneidwerkzeuge GmbH & Co. KG<br>
                        An der Asbacher Straße 5<br>
                        98574 Schmalkalden
                    </p>
                </div>
                <div class="footer-contact-container">
                    <p>
                        <a href="tel:+49 3683 6420"><i class="fal fa-phone"></i> +49 3683 642-0</a>
                        <a href="mailto:info@wms-sm.com"><i class="fal fa-envelope"></i> info@wms-sm.com</a>
                    </p>
                </div>
                <div class="footer-social-container">
    
                </div>
            </div>
            <div class="col-md-3 col-xs-6 col-12">
                <ul class="footer-nav footer-nav-category">
                    {#each categories.get_MainCats() as cat}
                        <li><Link path={[cat.id]} text={cat.name} class='link-hover link-uppercase'/></li>
                    {/each}
                </ul>
            </div>
            <div class="col-md-3 col-xs-6 col-12">
                <ul class="footer-nav footer-nav-static">
                    <li><a href={i18n.getString('url-homepage')} class='link-hover link-uppercase' target="_blank">Premium Cutting Solutions</a></li>
                </ul>
            </div>
            <div class="col-12">
                <div class="grid">
                    <div class="footer-copyright-hr"></div>
                    <div class="col-sm-auto col-12 copyright">© {year} MWS Schneidwerkzeuge</div>
                    <div class="col-sm-auto col-12 footer-nav-copyright-container">
                        <ul class="footer-nav footer-nav-copyright">
                            <li><Link path={['impressum']} text={i18n.getString('linktext-impressum')} class='link-hover'/></li>
                            <li><Link path={['datenschutz']} text={i18n.getString('linktext-datenschutz')} class='link-hover'/></li>
                            <li><Link path={['agb']} text={i18n.getString('linktext-agb')} class='link-hover'/></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<style>
    .footer {
        font-size: 1rem;
        padding-top: 4em;
    }
    .footer a,
    .footer a:active,
    .footer a:visited {
        text-decoration: none;
        color: inherit;
        transition: color 0.2s ease-out;
    }
    .footer a:hover {
        color: #007de3;
    }
    .footer :global(.footer-logo) {
        max-height: 4em;
    }
    .footer-contact-container a {
        display: block;
        line-height: 1.5em;
    }
    .footer-address-container i,
    .footer-contact-container i {
        color: #aaa;
    }
    .footer-nav {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-flow: column nowrap;
    }
    .footer-nav li {
        padding: 0;
        margin: 0;
    }
    .footer-nav :global(a) {
        display: block;
        line-height: 1.5em;
    }
    .footer-nav-copyright-container {
        flex-grow: 0;
    }
    .footer-copyright-hr {
        border-top: 1px solid #aaa;
        padding: 0;
        margin: 0 -15px;
        flex: 1 0 100%;
    }
    .footer-nav-copyright {
        flex-flow: row nowrap;
        gap: 1em;
    }
    .footer-nav-copyright li {
        padding: 0;
    }
</style>