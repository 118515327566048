import { readable } from "svelte/store"

let languageData = {
    loading: true,
    list:  [],
    selected: {slug:'de',name:'Deutsch',lang:'main'},
    default:  {slug:'de',name:'Deutsch',lang:'main'}
}

let init = false;
let initPromise:Promise<{}>

let update = () => {}

function load( callback?: Function ) {

    console.info('languageStore:load');

    initPromise = new Promise((resolve) => {
        fetch('/api/languages',{
            method: 'GET'
        }).then(
            response => response.json()
        ).then(data => {
            // Process the data returned from the API
            data.forEach((lang) => {
                if (lang.active) {
                    languageData.list.push(lang)
                }
            })
            if (typeof callback === 'function') {
                callback();
            }
            languageData.loading = false;
            update()
            console.info('languageStore:loaded');
            resolve(true)
        }).catch(error => {
            // Handle any errors that occur during the fetch
            console.error('Error:', error)
            languageData.loading = false
            update()
            resolve(false)
        });
    });

}

function loaded (callback?:Function) {

    if (!init) {
        init = true
        load()
    }

    callback = callback || (() => { return true })

    return new Promise((resolve) => {
        initPromise.then(() => {
            console.info('languageStore:loaded:true')
            resolve(callback());
        })
    })

}

function select( slug:string ) {

    console.info('languageStore:select', slug);

    let langData = getLanguage(slug)
    if (!langData) {
        console.warn('languageStore:select',slug,'langauge not found')
        return false
    }

    languageData.selected = langData
    update()

    return true

}

function getLanguage( slug?:string ) {

    console.info('languageStore:getLanguage',slug)

    console.debug(languageData)

    slug = slug || languageData.selected.slug
    let data = null

    languageData.list.every((lang) => {
        console.debug('languageStore:getLanguage',lang,lang.slug,slug)
        if (lang.slug === slug) {
            data = lang
            return false // exit loop
        }
        return true // check next
    })

    return data // null or language object

}

function setDefault () {

    let defaultLang = getLanguage('de')

    if (defaultLang) {
        languageData.default = defaultLang
    }

}

function getDefault () {

    return languageData.default

}

function getApiCode () {

    console.info('languageStore:getApiCode')

    return languageData.selected.lang;

}

function getSlug () {

    console.info('languageStore:getSlug')

    return languageData.selected.slug;

}

function create() {

    console.info('languageStore:create')

    const { subscribe } = readable(languageData, set => {
        update = () => set(languageData);
    });

	return {
        subscribe,
        loaded,
        select,
        getDefault,
        getLanguage,
        getApiCode,
        getSlug
	};
}

export const language = create()