<script lang="ts">

</script>

<div class="loading-overlay">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100px" height="100px">
        <circle cx="50" cy="50" r="40" stroke-width="8" fill="none" stroke-dasharray="251.2" stroke-dashoffset="251.2" stroke="#aaa">
            <animate attributeName="stroke-dashoffset" repeatCount="indefinite" values="251.2;0;-251.2" keyTimes="0;0.5;1" dur="3"/>
        </circle>
    </svg>
</div>

<style>
    .loading-overlay {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
    }
</style>