<script lang="ts">

    import { pwa } from "../stores/pwaStore";
    import { language } from "../stores/languageStore";
    import { location } from "../stores/locationStore"
    import Header from "./Header.svelte";
    import Footer from "./Footer.svelte";
    import MainContent from "./MainContent.svelte";
    import Loading from "./Loading.svelte";

    if (typeof window !== "undefined") console.info("App initialized")

    pwa.loaded().then(()=>{
        language.loaded().then(()=>{
            location.publishLocation();
        });
    });

</script>

{#if $location.loading}
    <Loading />
{:else}
    <Header />

    <div class="content">
        <MainContent />
    </div>

    <Footer />
{/if}