<script lang="ts">

    import { language } from "../stores/languageStore"
    import { location } from "../stores/locationStore";

    let open = false;

</script>

<div class="language-select" class:open={open}>
    <a href="#" class="language-select-current" on:click="{() => open = !open}"><i class="fal fa-globe"></i>{$language.selected.slug}</a>
    <ul>
        {#if $location && $language}
            {#each $language.list as lang}
                <li><a href="{location.getURL(location.getPathArray(), lang.slug)}" {...{/*on:click|preventDefault={() => location.pushState(location.getURL(location.getPathArray(), lang.slug))}*/}}>{lang.label}</a></li>
            {/each}
        {/if}
    </ul>
</div>

<style>
    .language-select {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0 1em;
        transition: color 0.2s ease-out;
        cursor: pointer;
    }
    .language-select:hover {
        color: #007de3;
    }
    .language-select-current {
        text-transform: uppercase;
        font-size: 0.8em;
        white-space: nowrap;
        line-height: 2em;
        cursor: pointer;
    }
    .language-select-current i {
        font-size: 1.25em;
        margin-right: 0.5em;
    }
    .language-select ul {
        display: none;
        position: absolute;
        margin: 0;
        padding: 1em;
        min-width: 10em;
        background-color: #fff;
        color: #003057;
        box-shadow: 0px 2px 2px #0030575b;
        list-style: none;
        flex-flow: column nowrap;
        gap: 0.25em;
        top: 100%;
        right: 0;
        z-index: 1000;
    }
    .language-select.open ul,
    .language-select:hover ul {
        display: flex;
    }
    .language-select a,
    .language-select a:active,
    .language-select a:visited {
        display: block;
        color: inherit;
        text-decoration: none;
        transition: color 0.2s ease-out;
    }
    .language-select a:hover {
        color: #007de3;
    }
</style>