<script lang="ts">

    import { i18n } from "../stores/i18nStore"
    import { language } from "../stores/languageStore"
    import { location } from "../stores/locationStore";
    import { products } from "../stores/productStore"

    function submitSearchForm (ev) {
        location.pushState(location.getURL(['search'])+'?search='+encodeURIComponent($products.search))
    }

</script>

<form class="searchform" on:submit|preventDefault={submitSearchForm}>
    <input type="text" placeholder={i18n.getString('searchfield-placeholder', $language.selected.slug)} bind:value={$products.search}>
    <button type="submit"><span>Search</span><i class="far fa-search"></i></button>
</form>

<style>
    .searchform {
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
    }
    .searchform input {
        padding: 0.75em 1em;
        border-radius: 0;
        border: 1px solid #aaa;
        margin: 0;
        flex-grow: 1;
    }
    .searchform input:focus {
        outline: none;
        box-shadow: inset 0 0 5px #007DE3;
        border-color: #007DE3;
    }
    .searchform button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background-color: #007DE3;
        border: none;
        margin: 0;
        padding: 0 1em;
        font-size: 1.3em;
    }
    .searchform button span {
        display: none;
    }
</style>