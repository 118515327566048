<script lang="ts">
    
    import { i18n } from "../stores/i18nStore";
    import { language } from "../stores/languageStore";

    export let slug:string;
    export let replace = {};
    export let html = false;
    

</script>

{#await i18n.loaded()}
    ...
{:then loaded}
    {#if html}
        {@html i18n.getString(slug, $language.selected.slug, replace)}
    {:else}
        {i18n.getString(slug, $language.selected.slug, replace)}
    {/if}
{/await}
