import { readable, get } from "svelte/store"
import { categories } from "./categoryStore"
import { products } from "./productStore"
import { language } from "./languageStore"
import { page } from "./pageStore"
import { i18n } from "./i18nStore"
import { themeimage } from "./themeimageStore"
import { pwa } from "./pwaStore"

let locData = {
    lang: 'de',
    path: (typeof window !== "undefined" && window.location?.pathname) || "/",
    search: (typeof window !== "undefined" && window.location?.search) || "",
    hash: (typeof window !== "undefined" && window.location?.hash) || "",
    push: false,
    pop: false,
    pathArray: [],
    title: '-',
    loading: true
}

//locData.pathArray = locData.path.replace(/^\/+/, '').replace(/\/+$/, '').split('/') //did not work with language is now set at first publishLocation call

let update = () => {}

function getPathArray () {
    return locData.pathArray.slice()
}

function getURL ( pathArray:string[], langSlug?:string ) {

    //console.info('location:getURL')
    console.debug('location:getURL', pathArray, langSlug)

    langSlug = langSlug || locData.lang
    pathArray = pathArray.slice() // create copy to not modify the original pathArray
    if (pathArray.length === 1 && pathArray[0] === '') {
        pathArray.shift() // is homepage
    }

    if (langSlug !== language.getDefault().slug) {
        pathArray.unshift(langSlug)
    }

    pathArray.push('') // add slash to end
    pathArray.unshift('') // add slash to beginning

    let url = pathArray.join('/')

    console.debug(url)

    return url

}

function pushState ( newPath:string ) {

    console.info('location:pushState')

    history.pushState('','',newPath)

    return false;

}

async function navigate () {

    console.info('locationStore:navigate')

    window.scrollTo({  // scroll to top to get feel of page reload
        top: 0,
        left: 0,
    });

    let success = false
    let search = null
    let currentCat = null
    let currentProd = null
    let currentPage = null
    const lang = language.getLanguage()

    let pathArray = getPathArray()

    await categories.loaded()
    console.debug('locationStore:navigate:categories:loaded')
    await page.loaded()
    console.debug('locationStore:navigate:page:loaded')

    page.reset()
    categories.reset()
    products.reset()

    pathArray.every((slug,i) => {
        success = false
        if(slug === '' && i === 0 && pathArray.length === 1) { //homepage
            success = true
            return false;
        }
        if (slug === '') { // empty slug in url should not happen => 404
            console.error('locationStore:navigate','empty Slug',pathArray,currentCat,currentProd,currentPage,lang)
            return false;
        }

        if (i === 0 && slug === 'search') {
            search = true;
            success = true;
            return true;
        }

        if (i === 0 && page.exists(slug,lang.slug)) { // is static page
            currentPage = slug
            success = true
            return true
        }

        if (!currentCat && i === 0) { // see if it is a main category
            if (categories.get_category(slug)) { // category exists
                currentCat = slug
                success = true
                return true
            }
        }

        if (currentCat) { // subcategory or product
            let cat = categories.get_category(slug)
            if (cat) {
                if (cat.parent === currentCat) { // is subcat of currentCat
                    success = true
                    currentCat = slug
                    return true
                }
                return false
            }
            cat = categories.get_category(currentCat)
            let catProducts = categories.collectCategoryProducts(cat.id)
            if (catProducts.indexOf(slug) >= 0) {
                currentProd = slug
                if (pathArray.length === (i+1)) { // there should not be another layer
                    success = true
                }
                return false
            } else {
                console.error('locationStore:navigate','not a Product of this category',pathArray,currentCat)
            }
        }
        
    });

    if (success) {
        if (search) {
            let params = new URLSearchParams(locData.search);
            products.search(params.get("search"));
            return true;
        }
        if (currentPage) { // is content Page
            page.select(currentPage,lang.slug)
            return true;
        }
        if (currentProd) { // is product page
            products.select(currentProd)
        }
        if (currentCat) { // is category page
            if (currentProd) { // 
                categories.select(currentCat,false) // no need to load product list on product page
            } else {
                categories.select(currentCat,true) // load product list
            }
        }
        return true; // homepage
    }

    page.do404() // 404 page
    return true

}

async function publishLocation (_p?: string) {

    console.info('locationStore:publishLocation',_p)

 //   _p = _p || (typeof window !== "undefined" && window.location?.pathname)

    let _s: string
    let _h: string
    let _l = 'de'
    if (_p) {

        const parts = _p.split("#")
        console.debug('parts',parts)
        _p = parts.shift()
        _h = parts.join()

        if (_h) _h = "#" + _h

        const parts2 = _p.split("?")
        console.debug('parts2', parts2)
        _p = parts2.shift()
        _s = parts2.join()


        if (_s) _s = "?" + _s

    } else {
        _p = window.location?.pathname
        _h = window.location?.hash
        _s = window.location?.search
    }

    if (_p.split('/')[1].length === 2) { // if its a two letter slug assume its the language
        let _pTmp = _p.split('/')
        _pTmp.shift() // remove leading slash
        _l = _pTmp.shift() // move lang slug to _l
        _p = '/' + _pTmp.join('/') // add leading slash again
    }

    const newLocation = {
        lang: _l,
        path: _p,
        search: _p
            ? _s
            : typeof window !== "undefined" && window.location?.search,
        hash: _p ? _h : typeof window !== "undefined" && window.location?.hash,
        push: !!_p,
        pop: !_p,
        pathArray: [],
        title: '',
        loading: false,
    }
    console.debug('newLocation', newLocation)
    locData = newLocation
    locData.pathArray = locData.path.replace(/^\/+/, '').replace(/\/+$/, '').split('/')

    await language.loaded() // load languages
    language.select(locData.lang) // set language

    i18n.loaded() // start loading texts
    themeimage.loaded() // start loading images
    page.loaded() // start loading pages
    pwa.loadOfflineData() // start loading offline Data

    await i18n.loaded() // wait for texts to be loaded
    await themeimage.loaded() // wait for images to be loaded
    await page.loaded() // wait for pages to be loaded
    await pwa.loaded() // wait for offline data to be loaded

    let success = await navigate()
    locData.title = getTitle()
    console.debug(locData)

    update()

    console.info('locationStore:publishLocation','done',success,locData)

    return success;
    
}

function getTitle() {

    console.info('locationStore:getTitle')

    let _page = get(page)
    if (_page.isSelected) {
        return page.getTitle()
    }

    let _products = get(products)
    if (_products.selected !== null) {
        return _products.selected
    }

    let _categories = get(categories)
    if (_categories.selected !== null) {
        return _categories.selected
    }

    return i18n.getString('banner-box-headline')

}

function create() {

    console.info('locationStore:create')

    const { subscribe } = readable(locData, set => {
        update = () => set(locData);
    });

	return {
        subscribe,
        publishLocation,
        navigate,
        pushState,
        getURL,
        getPathArray,
        getTitle,
	};

}

export const location = create()