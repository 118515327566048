<script lang="ts">
    import Productlist from './Productlist.svelte'
    import Categorylist from "./Categorylist.svelte"
    import ProductDetail from "./ProductDetail.svelte"
    import ProductFilter from './ProductFilter.svelte'
    import ContactBanner from './ContactBanner.svelte'
    import { products } from "../stores/productStore"
    import { categories } from '../stores/categoryStore'
    import { page } from '../stores/pageStore'
    import { i18n } from '../stores/i18nStore'
    import Page from './Page.svelte'
    import Breadcrumb from './Breadcrumb.svelte'
    import I18nString from './I18nString.svelte'
    import Banner from './Banner.svelte'
    import SubcategoryList from './SubcategoryList.svelte'

</script>

{#if $page.isSelected}
    <Banner />
    <div class="container">
        <Breadcrumb />
        <Page />
    </div>
{:else if $products.search}
    <div class="container">
        <h2><I18nString slug="productsearch-headline" replace={{search:$products.search}} /></h2>
        <Productlist />
    </div>
{:else if $products.selected}
    <div class="container" itemscope itemtype="https://schema.org/Product">
        <Breadcrumb />
        <ProductDetail />
    </div>
    <ContactBanner />
{:else if $categories.selected === null}
    <Banner />
    <div class="container">
        <Categorylist />
    </div>
{:else}
    {@const cat = categories.get_category($categories.selected)}
    {#if cat['children'].length}
        <div class="container">
            <Breadcrumb />
            <SubcategoryList />
            <Productlist />
        </div>
    {:else}
        <div class="container">
            <Breadcrumb />
            <h2><I18nString slug="productlist-headline" replace={{catname:cat.name}} /></h2>
            <Productlist />
        </div>
    {/if}
    <ContactBanner />
{/if}